<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('hall.default[3]')"
            left-arrow
            @click-left="$router.go(-1)"
    />
      <div class="header0">
          <div class="MyHeader2">
              <img class="head" :src="`./static/head/${UserInfo.header}`" />
          </div>
          <div>{{ UserInfo.username.substring(0,15) }}</div>
      </div>
      <div class="infoBox" style="line-height: 25px;color: #8CBFC0">
          <div>
              <div style="font-size:15px;color: #e7e7e7">{{ UserInfo.username.substring(0,15) }}</div>
              <div>{{$t('bindAccount.fields[0]')}} :{{CardInfo.name}}</div>
          </div>
          <div>
              <div style="font-size:15px"> USDT{{ $t("hall.default[1]") }}:
                  <span style="color: #DCA787">{{ parseFloat(UserInfo.balance).toFixed(2)}}</span></div>
              <div>{{$t('buy.label[2]')}} :{{CardInfo.card_no}}</div>
          </div>
      </div>
    <div class="ScrollBox">
            <div class="infoBox">
                <div style="display: flex;padding: 15px">
                    <div style="line-height: 25px;width: 50%">
                        <div style="font-size: 25px;color: #DAA78A;font-weight: bold">{{parseFloat(CoinInfo.price).toFixed(2)}}</div>
                        <div>{{$t('sell.label[3]')}}</div>
                        <div style="color: #86C0C1">USDT</div>
                    </div>
                    <div style="line-height: 25px;width: 50%">
                        <div style="font-size: 25px;font-weight: bold">{{parseFloat(CoinInfo.bestPrice).toFixed(2)}}</div>
                        <div>{{$t('sell.label[4]')}}</div>
                        <div style="color: #86C0C1">USDT</div>
                    </div>
                </div>
                <div>
                <van-field readonly class="input" :border="false"
                           v-model="BankInfo.price"
                        :label="$t('sell.label[5]')"
                        :placeholder="$t('sell.placeholder[0]')"
                />
                  <van-button color="#DAA78A" round size="min" style="width: 140px;height: 35px;margin-left: 120px" @click="setPrice">
                    <span style="color:#FFF;font-size: 14px">{{$t('sell.label[7]')}}</span></van-button>
                </div>
                <div>
                <van-field class="input" :border="false"
                           v-model="BankInfo.total_number"
                        :label="$t('sell.label[6]')"
                        :placeholder="$t('sell.placeholder[1]')"
                />
                  <van-button color="#DAA78A" round size="min" style="width: 100px;height: 30px;margin-left: 120px" @click="setAmount">
                    <span style="color:#FFF;font-size: 14px">{{$t('sell.label[8]')}}</span></van-button>
                </div>
            </div>
      <div style="text-align: center;margin-top: 20px">
        <p class="btn" @click="onSubmit">{{ $t('sell.label[9]') }}</p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'BindAccount',
  components: {

  },
  props: [],
  data() {
    return {
      tabsActive: 0,
      weixinAcc: '',
      showUsdtType: false,
      isVertical:true,
      robotList:[],
      CardInfo:{},
      BankInfo:{price:null,total_number:null},
      CoinInfo:{price:0,bestPrice:0},
      columns : ['ERC20', 'TRC20', 'OMNI'],
      douyinImg: [],
      kuaishouImg: [],
      accountArr: [],
      bestPrice:0,
      priceRate:0,
      leveldata:[],
      tabsList: [],
    }
  },
  computed: {
  },
  watch: {

  },
  created() {
    var that = this;
    this.$toast.loading();
    this.$Model.GetUserInfo((data) => {
      var userinfo = data.info;
      var level = userinfo.vip_level;
      that.$Model.GetLevelData(data=>{
        for (var i=0;i<data.length;i++){
          var lldata = data[i];
          if (lldata['grade']==level){
            // that.bestPrice = parseFloat(lldata['usdt']).toFixed(2);
            that.priceRate = lldata['pump'];
            // that.bestPrice = parseFloat(usdtinfo['price']*(1+rate)).toFixed(2);
            // that.bestPrice = 0;
            break
          }
        }
        that.$Model.GetCommonData(data=>{
          let currs = data.currencylist;
          let usdtinfo = data.usdtinfo;
          that.CoinInfo['price'] = usdtinfo['price']
          currs.forEach(item=>{
            if (item['name']=="USDT"){
              // that.CoinInfo = item;
              var rr = 1+that.priceRate*100/100;
              that.CoinInfo['bestPrice'] = parseFloat(usdtinfo['price']*rr).toFixed(2);
              console.log("curr best price"+that.CoinInfo['bestPrice'])
            }
          })
          that.$Dialog.Close();
        })
      });
    });
    this.$Model.GetBankCardList(data=>{
      this.CardInfo = data.data[0];
    });

    this.$Model.GetRobotTasks({},(data) => {
      // debugger
      if (data['code']==1){
        this.robotList = data['info']
      }
    });

    this.$parent.navBarTitle = this.$t('bindAccount.default[0]')
    // this.tabsList = this.InitData.taskclasslist.filter(item=>item.bind_status==1&&item.state==1)
    // this.accountArr = this.tabsList.flatMap(item=>item.bind_type==2?[this.UserInfo[item.bind_field]?[{url:this.ApiUrl+this.UserInfo[item.bind_field]}]:[]]:(this.UserInfo[item.bind_field]||''))
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    setPrice(){
      var price = this.CoinInfo.bestPrice
      this.BankInfo.price = parseFloat(price).toFixed(2);
    },
    setAmount(){
      this.BankInfo.total_number = this.UserInfo.balance;
    },
    onSubmit() {
      var tt_number = this.BankInfo.total_number*1;
      var balance =this.UserInfo.balance*1;
      if (this.BankInfo.price<=0){
        this.$Dialog.Toast(this.$t('mytask2[9]'));
        return;
      } else if (tt_number<=0){
        this.$Dialog.Toast(this.$t('mytask2[7]'));
        return;
      }else if (tt_number>balance) {
        this.$Dialog.Toast(this.$t('mytask2[8]'));
        return;
      }
        this.BankInfo.name = this.CardInfo['name'];
      this.BankInfo.card_no = this.CardInfo['card_no'];
      this.BankInfo.task_type = 2;
      this.BankInfo['task_id'] = 0;
      this.BankInfo['ordertype'] = 2;

      this.$Model.SubmitTaskOrder(
              this.BankInfo,
              (data) => {
                let msg = '';
                debugger
                switch (data.code){
                  case 1:
                    msg = this.$t('common3[0]');
                    break;
                  case -1:
                    msg = this.$t('mytask2[7]');
                    break;
                  case -2:
                    msg = this.$t('hall2[2]');
                    break;
                  case -3:
                    msg = this.$t('mytask2[8]');
                    break;
                  case -4:
                    msg = this.$t('mytask2[7]');
                    break;
                  case -5:
                    msg = this.$t('mytask2[10]');
                  case -6:
                    msg = this.$t('common5[5]');
                    break
                  case -7:
                    msg = this.$t('common4[7]');
                    break
                  case -8:
                    msg = this.$t('common4[8]');
                    break
                  case -9:
                    msg = this.$t('common4[9]');
                    break
                  default:
                    msg = data.code_dec;
                    break;
                }
                let duration = 2000;
                if (data.wait){
                  duration = data.wait*1000
                }
                let msgg = {
                  message: msg,
                  duration: duration
                }
                this.$toast(msgg);
                if (data.code==1){
                  this.$router.push("/taskDetail/"+data.id);
                }
              }
      );
    },
    onChangeType(obj,value){
      this.UserInfo.usdttype = value;
      this.showUsdtType = false;
    },
  }
}
</script>
<style scoped>
    .PageBox {
        background-color: #0F4F4E;
    }

    .van-nav-bar {
        background-color: #0F4F4E;
    }
    .van-nav-bar>>>.van-nav-bar__title{
        color:#FFFEFF;
    }
    .van-nav-bar>>>.van-nav-bar__arrow {
        color:#FFFEFF;
    }


    .van-cell.input >>> .van-field__control{
        border-bottom: 1px solid;
    }

    .van-cell>>>.van-cell__title{
        font-size: 14px;
    }
    .van-uploader>>>.van-uploader__upload,.van-uploader>>>.van-uploader__preview-image,.van-uploader>>>.van-uploader__preview{
        margin: 0;
        background-color: #f7f8fa;
        border-radius: 8px;
    }
    .header0 {
        display:flex;
        font-size: 17px;
        color: #588686;
        padding: 10px;
        padding-left: 15px;
        line-height: 50px;
        margin: 0px auto;
    }

    .MyHeader2 {
        font-size: 19px;
        border-radius: 50%;
        background: pink;
        overflow: hidden;
        width: 42px;
        height: 42px;
        margin-right: 10px;
    }
    .MyHeader2 img {
        width: 100%;
    }
    .panelHeader {
        margin-top: 0px;
        border: 1px solid #cccccc;
        border-radius: 5px;
        color: #cccccc;
        width: 40%;
        text-align: center;
        margin-bottom: 10px;
    }
    .van-cell {
        background: #337F7B;
    }

    .PageBox .van-cell>>>.van-cell__title{
        font-size: 15px;
        font-weight: bold;
        color: #FFFEFF !important;
    }
    .van-cell >>> .van-field__control {
        color: #8CBFC0;
        padding-left: 7px;
        height: 100%;
    }

    .van-cell >>> .van-field__control::-webkit-input-placeholder {
        color: #8CBFC0;
        font-family: Alibaba;
    }

    .van-cell >>> .van-field__control::-moz-placeholder {
        color: #8CBFC0;
        font-family: Alibaba;
    }

    .van-popup {
        background-color: #4e51bf;
    }

    .hallswipe {
        width: 96%;
        margin-left: 6px;
        padding: 15px;
        height: 60px;
        z-index: 50;
        margin-top: 5px;
        margin-bottom: 5px;
        color:#cccccc;
        background-color: #337F7B;
    }

    .robotheader{
        font-size: 19px;
        border-radius: 50%;
        background: pink;
        overflow: hidden;
        width: 32px;
        height: 32px;
    }
    .infoBox {
        width: 95%;
        margin-left: 10px;
        border-radius: 5px;
        background-color: #337F7B;
        padding: 10px;
        color: #e7e7e7;
        margin-bottom: 10px;
    }

    .btn {
        width: 90%;
        padding: 10px 50px;
        border-radius: 5px;
        background-color: #DAA78A;
        color: #FFFEFF;
        font-size: 18px;
        text-align: center;
        margin: 15px 20px 30px;
    }
</style>
